import React from "react";
import styled from "styled-components";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
const ContactUs = () => {
  const callUs = () => {
    window.location.href = "tel:+971555366984";
  };

  const sendEmail = () => {
    // mail to link - royelbengalgold@gmail
    const mail = "royelbengalgold@gmail.com";
    window.location.href = `mailto:${mail}`;
  };

  const ourAddress = () => {

    const latitute = 25.2721767425537;
    const longitute = 55.3012504577637;

    // google map link - https://www.google.com/maps/search/?api=1&query=25.2691,55.3095
    // window.open(`https://www.google.com/maps/search/?api=1&query=${latitute},${longitute}`, "_blank");
    //window.open(`https://www.google.com/maps?q=25.2721767425537,55.3012504577637`, "_blank");

  // try to open mobile app if exits not then open in browser
    const userAgent = navigator.userAgent || navigator.vendor || window.opera; 
    if (/android/i.test(userAgent)) {
      window.open(`geo:${latitute},${longitute}`, "_blank");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open(`maps://maps.google.com/maps?daddr=${latitute},${longitute}`, "_blank");
    } else {
      window.open(`https://www.google.com/maps?q=${latitute},${longitute}`, "_blank");
    }


  }

  const whatsappMessage = () => {
    // whatsapp message link - https://wa.me/971555366984
    const whatsapp = "971555366984";
    // window.location.href = `https://wa.me/${whatsapp}`;
    // new window open
    window.open(`https://wa.me/${whatsapp}`, "_blank");

  }

  return (
    <Wrapper>
      <div>
        <div>
          {/* logo */}
          <img src="logo.png" alt="logo" style={{ width: "100px" }} />
        </div>

        <div>
          Customer Support <br />
          24/7 Support
        </div>

        <div>
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridGap: "10px",
              padding: "10px",
              borderRadius: "10px",
              color: "black",
              cursor: "pointer",
            }}>
            <div
              style={{
                borderRadius: "10px 10px 10px 10px",
                width: "150px",
                height: "150px",
                backgroundColor: "#272727",
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={callUs}>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#000000",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <CallRoundedIcon style={{ fontSize: 30, color: "#daa521" }} />
              </div>

              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}>
                Call Us
              </span>

              <span
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                }}>
                Talk to our executives
              </span>
            </div>


            <div
              style={{
                borderRadius: "10px 10px 10px 10px",
                width: "150px",
                height: "150px",
                backgroundColor: "#272727",
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={whatsappMessage}
              >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#000000",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  
                }}
                
                >
                <MessageRoundedIcon
                  style={{ fontSize: 30, color: "#daa521" }}
                />
              </div>

              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}>
                Whatsapp
              </span>

              <span
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                }}>
                Chat with us
              </span>
            </div>


            {/* mail  */}
            <div
              style={{
                borderRadius: "10px 10px 10px 10px",
                width: "150px",
                height: "150px",
                backgroundColor: "#272727",
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={sendEmail}
              >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#000000",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <EmailRoundedIcon style={{ fontSize: 30, color: "#daa521" }} />
              </div>

              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}>
                Mail
              </span>

              <span
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                }}>
                Drop Us a line
              </span>
            </div>



            <div
              style={{
                borderRadius: "10px 10px 10px 10px",
                width: "150px",
                height: "150px",
                backgroundColor: "#272727",
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={ourAddress}
              >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#000000",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <LocationOnRoundedIcon
                  style={{ fontSize: 30, color: "#daa521" }}
                />
              </div>

              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}>
                Our Address
              </span>

              <span
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                }}>
                Reach us at
              </span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

// // with background image
const Wrapper = styled.div`
  height: 100vh;
  background-image: url("background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  background-color: #464646;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  /* text-shadow: 0 0 10px #000; */
  /* font-family: "Lobster", cursive; */
  text-align: center;
  padding: 0 1rem;
  text-transform: capitalize;
`;

const AppBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
`;

export default ContactUs;
