import React, { useEffect } from "react";
import styles from "styled-components";

const PriceItem = ({ name, subName, weight, price, status }) => {


  



  return (
    <div
      style={{
        width: "100%",
        color: "black",
        marginTop: "5px",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          color: "black",
          fontWeight: "bold",
          fontSize: "14px",
          border: "2px solid #daa521",
        }}>
        <div
          style={{
            borderRadius: "0px 10px 0px 10px",
            paddingTop: "11px",
            paddingBottom: "11px",
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
          }}>
          <Title>{name}</Title>
          <SubTitle>{subName}</SubTitle>
        </div>
        <div
          style={{
            width: "250px",
            borderLeft: "2px solid #989898",
            borderRight: "2px solid #989898",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
          }}>
          <span>{weight}</span>
        </div>
        <div
          style={{
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "10px 0px 10px 0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
          }}>
          <PriceAmount
            style={{
              color:
                status === "up" 
                  ? "#076407"
                  : status === "down" 
                  ? "#ff0000"
                  : "#141414 ",
            }}>
            {price}
          </PriceAmount>
          <span
            style={{
              fontSize: "10px",
              marginTop: "5px",
            }}></span>
        </div>
      </div>
    </div>
  );
};

const Title = styles.span`
    font-size: 30px;
    padding-left: 10px;
    color: #5f4b1a;

    @media (max-width: 768px) {
        font-size: 20px;
    }

`;

const SubTitle = styles.span`
    font-size: 20px;
    padding-top: 6px;
    padding-left: 2px;
    color: #5f4b1a;

    @media (max-width: 768px) {
        font-size: 15px;
    }


`;

const PriceAmount = styles.span`
    font-size: 35px;
    padding-left: 10px;
    color: #333333;

    @media (max-width: 768px) {
        font-size: 25px;
    }

`;

export default PriceItem;
