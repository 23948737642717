// TradingViewWidget.js

import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function GoldChart() {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('technical-analysis-chart-demo') && 'TradingView' in window) {
          new window.TradingView.widget({
            container_id: "technical-analysis-chart-demo",
            width: "100%",
            height: "100%",
            autosize: true,
            symbol: "TVC:GOLD",
            interval: "60",
            timezone: "exchange",
            theme: "dark",
            style: "1",
            toolbar_bg: "#f1f3f6",
            withdateranges: true,
            hide_side_toolbar: true,
            allow_symbol_change: false,
            save_image: false,
            studies: ["MASimple@tv-basicstudies"],
            show_popup_button: false,
            popup_width: "1000",
            popup_height: "650",
            locale: "en",
            calendar: true,
            hotlist: true,
            details: true,
          });
        }
      }
    },
    []
  );

  return (
    <div 
    style={{
      width: '100vw',
      height: '100vh',
    }}
    >
      <div className='tradingview-widget-container'>
      <div id='technical-analysis-chart-demo' />
      <div className="tradingview-widget-copyright">
        {/* <a href="https://www.tradingview.com/symbols/GOLD/" rel="noopener" target="_blank"><span className="blue-text">GOLD stock chart</span></a> by TradingView */}
      </div>
    </div>
    </div>
  );
}
