import React from "react";
import { Timeline } from "react-tradingview-embed";

const GoldTimeLine = () => {

  return (
    <Timeline
      widgetProps={{
        feedMode: "symbol",
        symbol: "TVC:GOLD",
        isTransparent: false,
        displayMode: "regular",
        locale: "en",
        colorTheme: "dark",
        width: "100%",
        // height: "100%",
      }}
    />
  );
};

export default GoldTimeLine;
