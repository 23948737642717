import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import BarChartIcon from "@mui/icons-material/BarChart";
import GridViewIcon from "@mui/icons-material/GridView";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { useNavigate } from "react-router-dom";

const BottomNavigationComponent = () => {

  const [value, setValue] = React.useState("/");
  let navigate = useNavigate();

  return (
    <Paper
    sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
    elevation={3}>
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        navigate(newValue)
      }}
      style={{ backgroundColor: "#0F0F0F" }}>
      <BottomNavigationAction
        label="Spot"
        value="/"
        icon={<SubtitlesIcon />}
        style={{ color: value === "/" ? "white" : "grey" }}
      />
      <BottomNavigationAction
        label="Chart"
        value="gold-chart"
        icon={<BarChartIcon />}
        style={{ color: value === "gold-chart" ? "white" : "grey" }}
      />
      <BottomNavigationAction
        label="Contact us"
        value="contact-us"
        icon={<HeadsetMicIcon />}
        style={{ color: value === "contact-us" ? "white" : "grey" }}
      />
      <BottomNavigationAction
        label="More"
        value="more"
        icon={<GridViewIcon />}
        style={{ color: value === "more" ? "white" : "grey" }}
      />
    </BottomNavigation>
  </Paper>
  )
}

export default BottomNavigationComponent