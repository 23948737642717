// SocketConnection.js
import React, { useEffect, useState } from "react";
import { io } from "socket.io-client"; // version ^4.5.4

const SocketConnection = () => {
  const [data, setData] = useState(null);
  const [isConnected, setIsConnected] = useState("disconnected");

  useEffect(() => {
    setIsConnected("connecting...");
    const socket = io("https://api.goldpriceupdate.com/", {
      query: {
        secret_key: "JUW0FexuguFLpsMNIdveXckJJwSraF69",
        appPackage:'com.app.royalbengalgold'
      },
      transports: ["websocket"],
    });
    socket.on("connect", () => {
      setIsConnected("connected");
      socket.emit("subscribe_gold_price");
      socket.on("gold_price_update", (response) => {
        setData(response);
      });
    });

    socket.on("disconnect", () => {
      console.log("disconnected");
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  return (
    <div>
      <h1>{isConnected}</h1>
        <div>
        <p>{data}</p>
      </div>
    </div>
  );
};

export default SocketConnection;
