import React, { useEffect, useRef } from "react";
import moment from "moment";

const CurrentTime = () => {
  // get current time in 12 hour format with AM / PM with current location time zone realtime
  //   const getCurrentTime = () => {
  //     const today = new Date();
  //     const time = today.toLocaleString("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       hour12: true,
  //     });
  //     return time;
  //   };

  //   realtime update time

  const timeRef = useRef(null);

  const getCurrentTime = () => {
    const today = moment().format("hh:mm A");
    return today;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      timeRef.current.innerHTML = getCurrentTime();
    }, 500);

    return () => {
        clearInterval(interval);
    };
  }, []);

  return (
    <span
      ref={timeRef}
      style={{
        padding: "0px",
        margin: "0px",
        lineHeight: "13px",
        fontSize: "12px",
      }}></span>
  );
};

export default CurrentTime;
