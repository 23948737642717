import React from "react";
import {
  MarketOverview,
  MarketData,
  EconomicCalendar,
  Ticker,
  SingleTicker,
  MiniChart,
  SymbolOverview,
  SymbolInfo,
  ForexCrossRates,
  ForexHeatMap,
  CryptocurrencyMarket,
  FundamentalData,
  CompanyProfile,
  Screener,
} from "react-tradingview-embed";

const GoldAnalysis = () => {
  return (
    <div
      style={{
        display: "flex",
      }}>
      <Ticker
        colorTheme="dark"
        showSymbolLogo={true}
        locale="en"
        symbols={[
          {
            proName: "FOREXCOM:SPXUSD",
            title: "S&P 500",
          },
          {
            proName: "FOREXCOM:NSXUSD",
            title: "US 100",
          },
          {
            proName: "FX_IDC:EURUSD",
            title: "EUR/USD",
          },
        ]}
      />

      <MarketOverview />
      <MarketData />
      <EconomicCalendar />

      {/* <TickerTape /> */}
      <SingleTicker />
      <MiniChart />
      <SymbolOverview />
      <SymbolInfo />
      <ForexCrossRates />
      <ForexHeatMap />

      <CryptocurrencyMarket />
      <FundamentalData />
      <CompanyProfile />
      <Screener />
    </div>
  );
};

export default GoldAnalysis;
