import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import styled from "styled-components";
import BottomNavigationComponent from "../components/BottomNavigationComponent";
import SpotPage from "./SpotPage";
import { Outlet } from "react-router-dom";


import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SocketConnection from "../redux/socket/socketAction";

export default function FixedBottomNavigation() {




  return (
    <Wrapper>
      <CssBaseline />

      {/* <SpotPage /> */}
      <Outlet />

      <BottomNavigationComponent />
    </Wrapper>
  );
}

// // with background image
const Wrapper = styled.div`
  height: 100vh;
  background-image: url("background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  background-color: #464646;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  /* text-shadow: 0 0 10px #000; */
  /* font-family: "Lobster", cursive; */
  text-align: center;
  padding: 0 1rem;
  text-transform: capitalize;
`;
