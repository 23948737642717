import React from "react";

const HeadingLine = () => {
  return (
    <div
      style={{
        width: "100%",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          backgroundColor: "#daa521",
          borderRadius: "10px",
          color: "black",
          fontWeight: "bold",
          fontSize: "14px",
          border: "2px solid #000000",
        }}>
        <div
          style={{
            borderRadius: "0px 10px 0px 10px",
            padding: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
          }}>
          <span>METALS</span>
        </div>
        <div
          style={{
            width: "250px",
            borderLeft: "2px solid #5f5f5f",
            borderRight: "2px solid #5a5959",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "15px",
          }}>
          <span>WEIGHT</span>
        </div>
        <div
          style={{
            width: "100%",
            padding: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "10px 0px 10px 0px",
          }}>
          <span>PRICE</span>{" "}
          <span
            style={{
              fontSize: "10px",
            }}>
            AED
          </span>{" "}
        </div>
      </div>
    </div>
  );
};

export default HeadingLine;
