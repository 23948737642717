import React from "react";

const TodayCalender = () => {
  const getTodayDate = () => {
    const today = new Date();
    const date = today.getDate();
    const month = today.toLocaleString("default", { month: "short" });
    // year
    const year = today.getFullYear();
    return `${date} ${month} ${year}`;
  };

  const day = new Date().toLocaleString("default", { weekday: "long" });

  return (
    <span
      style={{
        padding: "0px",
        margin: "0px",
        lineHeight: "15px",
        fontSize: "12px",
      }}>
      {getTodayDate()}
      <br />
      {day}
    </span>
  );
};

export default TodayCalender;
