import React from "react";
import styled from "styled-components";
import PriceComponent from "./PriceComponent";
import AskPricePreview from "./AskPricePreview";
const BidAskPriceCard = ({ askPrice,bidPrice, todayHigh, todayLow }) => {
  // console.log("goldData", goldData);

  

  const [bidPirceStatus, setBidPriceStatus] = React.useState("up");
  const [askPirceStatus, setAskPriceStatus] = React.useState("up");

  // React.useEffect(() => {
  //   if (newData?.bid?.price > oldData?.bid?.price) {
  //     setBidPriceStatus("up");
  //   } else if (goldData?.bid?.price < oldData?.bid?.price) {
  //     setBidPriceStatus("down");
  //   } else {
  //     setBidPriceStatus("none");
  //   }

  //   if (newData?.ask?.price > oldData?.ask?.price) {
  //     setAskPriceStatus("down");
  //   } else if (oldData?.ask?.price < goldData?.ask?.price) {
  //     setAskPriceStatus("up");
  //   } else {
  //     setAskPriceStatus("none");
  //   }

  //   setOldData(newData);
  // }, [newData]);

  return (
    <CardView>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "bold",
            color: "black",
          }}>
          GOLD Oz BID
        </span>

        <PriceComponent bid={bidPrice} />

        <div>
          <span
            style={{
              backgroundColor: "#d74646",
              fontSize: "12px",
              borderRadius: "3px",
              padding: "1px 5px 2px 5px",
            }}>
            Low
          </span>
          <span
            style={{
              fontSize: "12px",
              color: "black",
              fontWeight: "bold",
            }}>
            {todayLow}
          </span>
        </div>
      </div>

      <div
        style={{
          width: "2px",
          height: "100%",
          backgroundColor: "black",
        }}>
        {" "}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "bold",
            color: "black",
          }}>
          GOLD Oz ASK
        </span>

        <AskPricePreview ask={askPrice} />

        <div>
          <span
            style={{
              backgroundColor: "#116a1e",
              fontSize: "12px",
              borderRadius: "3px",
              padding: "1px 5px 2px 5px",
            }}>
            High
          </span>
          <span
            style={{
              fontSize: "12px",
              color: "black",
              fontWeight: "bold",
            }}>
            {todayHigh}
          </span>
        </div>
      </div>
    </CardView>
  );
};

const CardView = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px #000;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`;

const PriceUpdate = styled.span`
  font-size: 35px;
  font-weight: bold;
  color: black;
  padding: 0px;
  padding-top: 10px;
  margin: 0px;
  line-height: 1px;

  @media (max-width: 768px) {
    font-size: 25px;
  }

  /* animation: blinker 1s linear infinite; */
  /* @keyframes blinker {
        50% {
            opacity: 0;
        }
    } */
`;

const PriceWrap = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */

  @media (max-width: 768px) {
  }
`;

export default BidAskPriceCard;
