import * as TYPES from "../actionTypes"
import io from "socket.io-client";

export const socketConnect = () => async (dispatch, getState) => {


    const oldSocket = getState().socketReducer.socket


    if (!oldSocket) {
        const socket = io("https://api.goldpriceupdate.com/", {
            query: {
                secret_key: "JUW0FexuguFLpsMNIdveXckJJwSraF69",
                appPackage: 'com.app.royalbengalgold'
            },
            transports: ["websocket"],
        });

        socket.on("connect", () => {
            socket.emit("subscribe_gold_price");
            socket.on("gold_price_update", (response) => {
                
            });

            dispatch({
                type: TYPES.SOCKET_REQUEST_SUCCESS,
                payload: socket
            })
        });

        socket.on("disconnect", () => {
            console.log("disconnected");
        });
    }



}