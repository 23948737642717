import { Avatar } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useRef } from "react";
const TimeFrame = () => {
  const getUAEtime = () => {
    // get UAE time
    const uaeTime = moment().utcOffset("+04:00").format("hh:mm A");
    return uaeTime;
  };

  const getUAEDay = () => {
    // get UAE time
    const uaeDay = moment().utcOffset("+04:00").format("dddd");
    return uaeDay;
  };

  const getUKtime = () => {
    // get united kingdom time
    const ukTime = moment().utcOffset("+01:00").format("hh:mm A");
    return ukTime;
  };

  const getUKDay = () => {
    // get UAE time
    const ukDay = moment().utcOffset("+01:00").format("dddd");
    return ukDay;
  };

  const getUSAtime = () => {
    // get USA time
    const usaTime = moment().utcOffset("-04:00").format("hh:mm A");
    return usaTime;
  };

  const getUSADay = () => {
    // get UAE time
    const usaDay = moment().utcOffset("-04:00").format("dddd");
    return usaDay;
  };

  const refUaeTime = useRef(null);
  const refUkTime = useRef(null);
  const refUsaTime = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      refUaeTime.current.innerHTML = getUAEtime();
      refUkTime.current.innerHTML = getUKtime();
      refUsaTime.current.innerHTML = getUSAtime();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <TimeBarWrapper
      style={{
        paddingTop: "10px",
        paddingLeft: "50px",
        paddingRight: "50px",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "1px",
        }}>
        {/* time */}
        <Avatar src="/uaeflag.png" />
        <span
          style={{
            padding: "0px",
            margin: "0px",
            lineHeight: "15px",
            fontSize: "12px",
          }}>
          {getUAEDay()} <br />
          <span ref={refUaeTime}></span>
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "1px",
        }}>
        {/* time */}
        <Avatar src="/united-kingdom.png" />
        <span
          style={{
            padding: "0px",
            margin: "0px",
            lineHeight: "15px",
            fontSize: "12px",
          }}>
          {getUKDay()} <br />
          <span ref={refUkTime}></span>
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "1px",
        }}>
        {/* time */}
        <Avatar src="/united-states.png" />
        <span
          style={{
            padding: "0px",
            margin: "0px",
            lineHeight: "15px",
            fontSize: "12px",
          }}>
          {getUSADay()} <br />
          <span ref={refUsaTime}></span>
        </span>
      </div>
    </TimeBarWrapper>
  );
};

const TimeBarWrapper = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  gap: 20px;


  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
`;

export default TimeFrame;
