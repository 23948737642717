import { createBrowserRouter } from "react-router-dom";
import { SingleTicker, TickerTape } from "react-tradingview-embed";
import GoldAnalysis from "../pages/GoldAnalysis";
import GoldChart from "../pages/GoldChart";
import GoldTimeLine from "../pages/GoldTimeLine";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Layout from "../pages/Layout";
import SpotPage from "../pages/SpotPage";
import ContactUs from "../pages/ContactUs";
import MorePage from "../pages/MorePage";
import SocketConnection from "../pages/SocketConnection";
import AdminLayout from "../components/AdminLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <SpotPage />,
      },
      {
        path: "more",
        element: <MorePage />,
      },
      {
        path: "gold-chart",
        element: <GoldChart />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "connection-socket",
        element: <SocketConnection/>
      }

    ]
  },
  {
    path: "gold-admin",
    element: <AdminLayout/>,
    children: [
      {
        path: "login",
        element: <div>login</div>
      }
    ]
  },
  {
    path: "gold-chart",
    element: <GoldChart />,
  },

  {
    path: "gold-analysis",
    element: <GoldAnalysis />,
  },
  {
    path: "ticker-list",
    element: <div>
      <TickerTape />
    </div>,
  },
  {
    path: "privacy_policy",
    element: <PrivacyPolicy />
  },
  {
    path: "gold-ticker",
    element: <div

    >
      <SingleTicker
        widgetProps={{
          width: "100%",
          symbol: "TVC:GOLD",
          colorTheme: "dark",
          isTransparent: false,
          locale: "en",
          autosize: true,
        }}
      />
    </div>,
  }
]);

export default router;