import React from "react";
import styled from "styled-components";
const AskPricePreview = ({ ask }) => {

  // console.log("ask", ask);

  return <WrapperNone><Title>{ask}</Title></WrapperNone>

  // return goldData.ask.status === "up" ? (
  //   <WrapperGreen>
  //     <Title>{goldData?.ask?.price.toFixed(2)}</Title>
  //   </WrapperGreen>
  // ) : goldData.bid.status === "down" ? (
  //   <WrapperRed>
  //     <Title>{goldData?.ask?.price.toFixed(2)}</Title>
  //   </WrapperRed>
  // ) : (
  //   <WrapperNone>
  //     <Title>{goldData?.ask?.price.toFixed(2)}</Title>
  //   </WrapperNone>
  // );
};

const WrapperRed = styled.div`
  display: flex;
  color: black;
  border: 1px solid black;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ec4141;
`;

const WrapperGreen = styled.div`
  display: flex;
  color: black;
  border: 1px solid black;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #2d9a26;
`;

const WrapperNone = styled.div`
  border: 1px solid black;
  display: flex;
  color: black;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
`;

const Title = styled.p`
  font-size: 30px;
  padding-left: 6px;
  padding-right: 6px;
  margin: 0px;

  @media (max-width: 768px) {
    font-size: 25px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

export default AskPricePreview;
